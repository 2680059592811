import './Header.scss';
import { Menu, ConfigProvider } from 'antd';
import { itemsMenu } from './data';
import { Container } from '../index';
import logo from '../../images/logo.png';
import email from '../../images/icons/email.svg';

const themeConfig = {
    components: {
        Menu: {
            darkItemSelectedBg: 'none',
            horizontalItemHoverColor: 'red',
            horizontalItemSelectedColor: 'red',
            darkItemHoverColor: '#b0852e',
            darkItemColor: '#fff',
            groupTitleFontSize: '20px',
            fontSize: '20px'
        },
    },
};

const Header = ({ footer }) => {
    const currentYear = new Date().getFullYear();

    return (
        <ConfigProvider theme={themeConfig}>
            <div className="katana__menu" style={footer ? { position: 'relative' } : { position: 'absolute' }}>
                <Container>
                    <div className='header__logo-menu'>
                        {footer ? (
                            <span className="footer__copyright">
                                Copyright © {currentYear} All rights reserved
                            </span>
                        ) : (
                            <img src={logo} alt="Logo" className='header__logo' />
                        )}
                        <Menu mode='horizontal' className='header__nav' theme='dark'>
                            {itemsMenu.map(item => (
                                <Menu.Item key={item.key} onClick={() => item.link && (window.location.href = item.link)}>
                                    {item.label}
                                </Menu.Item>
                            ))}
                        </Menu>
                        <div
                            className="email"
                            onClick={() => {
                                window.location.href = "mailto:info@katana-oil.id";
                            }}
                        >
                            <img src={email} alt="email icon" />
                            info@katana-oil.id
                        </div>
                    </div>
                </Container>
            </div>
        </ConfigProvider>
    );
}

export default Header;